.commission-part {
  padding: 70px 16px 88px;

  @media (max-width: 1200px) {
    padding: 56px 16px 112px;
  }

  
  svg {
    width: 600px;
  }

  @media (max-width: 650px) {
    svg {
      width: auto;
    }
  }

  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: stretch;
    align-items: stretch;
    grid-gap: 16px;
    .item, span {
      text-align: center;
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .item {
    &:first-child {
      background: var(--color-card-primary);
      grid-row: 1/3;
    }

    * {
      text-align: center;
    }

    .header {
      display: block;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    .short {
      display: block;
      margin: auto;
      max-width: 350px;
    }

    .mr-b {
      display: block;
      margin-bottom: 16px;
    }

    .mr-r {
      display: inline-block;
      margin-right: 24px;
    }

    @media (max-width: 1200px) {

    }
  }

  .text-light {
    color: var(--color-primary-text);
  }

}