.about-part {
  padding: 70px 16px;

  @media (max-width: 1200px) {
    padding: 56px 16px;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: stretch;
    align-items: stretch;
    grid-gap: 16px;

    &:first-of-type {
      margin-bottom: 140px;
    }

    &:nth-of-type(2) {
      .item, span {
        text-align: center;
      }
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(1, 1fr);

      &:first-of-type {
        margin-bottom: 112px;
      }
    }
  }

  .background {
    background: var(--color-card-primary);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 1/3;

    &:nth-child(2) {
      grid-column: 2;
    }

    svg {
      width: 600px;
    }

    @media (max-width: 1200px) {
      grid-column: 1 !important;
      grid-row: 1 !important;
    }

    @media (max-width: 650px) {
      svg {
        width: auto;
      }
    }
  }

  .item {}

  .list-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &::before {
      content: "";
      display: inline-block;
      background-color: var(--color-primary);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .item.second {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .item-content {
    text-align: left;
    max-width: 470px;

    .h3 {
      text-align: left !important;
    }

    br {
      display: block;
      height: 10px;
      content: "";
    }
  }
}