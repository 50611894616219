.loader {
  width: 340px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 32px);
  padding: 32px 16px;

  svg {
    overflow: visible;
    path {
      fill: var(--color-primary);
      animation: 1s infinite normal none running loader-animation;

      animation-delay: 1000s;

      &:nth-of-type(1) {
        animation-delay: 0s;
      }

      &:nth-of-type(2) {
        animation-delay: .125s;
        transform: rotate(90deg) translateY(-50px);
      }

      &:nth-of-type(3) {
        animation-delay: .25s;
        transform: rotate(180deg) translate(-50px, -50px);
      }

      &:nth-of-type(4) {
        animation-delay: .375s;
      }

      &:nth-of-type(5) {
        animation-delay: .5s;
      }

      &:nth-of-type(6) {
        animation-delay: .625s;
      }

      &:nth-of-type(7) {
        animation-delay: .75s;
      }

      &:nth-of-type(8) {
        animation-delay: .825s;
      }
    }
  }
}

@keyframes loader-animation {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}