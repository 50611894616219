footer.footer {
  padding: 56px 32px;
  background: #222B58;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 66px;
  justify-items: stretch;
  align-items: stretch;

  @media (max-width: 1000px) {
    padding: 56px 16px;
    grid-template-columns: repeat(1, 1fr);
  }

  * {
    text-align: center;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .h3 {
      color: var(--color-white);
      line-height: 30.8px;
    }

    .text {
      display: block;
      color: rgba(255, 255, 255, 0.8);
      max-width: 350px;
    }

    .link {
      max-width: 450px;
      cursor: pointer;
      color: var(--color-primary-text);
      text-decoration: none;
      display: block;

      margin-bottom: 18px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    button {
      margin-top: 40px;
      width: fit-content;
      min-width: 100px;
    }
  }

}