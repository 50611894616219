.main-part {
  margin-top: 67px;
  background: var(--color-card-primary);
  position: relative;
  padding: 56px 32px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1200px) {
    margin-top: 60px;
    padding: 40px 32px;
  }

  @media (max-width: 1000px) {
    padding: 40px 0;
    flex-direction: column-reverse;
  }


  .content {
    min-width: 500px;
    max-width: 50%;
    z-index: 1;

    .title {
      font-weight: bold;
      font-size: 56px;
      line-height: 60px;
      display: block;
      margin-bottom: 36px;
      text-align: center;
    }

    .text {
      display: block;
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 108px;
      text-align: center;
    }

    @media (max-width: 1200px) {
      min-width: 0;
      max-width: none;
      .title {
        font-size: 40px;
        line-height: 44px;
      }
      .text {
        margin-bottom: 40px;
      }
    }

    @media (max-width: 1000px) {
      width: 100%;
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 32px;
      button {
        &:first-child {
          margin-right: 22px;
        }
      }

      @media (max-width: 600px) {
        flex-direction: column;
        button {
          width: 100%;
          margin-right: 0 !important;
          &:first-child {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  img {
    max-width: 50%;

    @media (max-width: 1000px) {
      margin-bottom: 24px;
      max-width: 550px;
      width: 100%;

    }

  }

}