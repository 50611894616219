.transfer-part {
  position: relative;
  margin-top: 67px;
  background: var(--color-white);
  padding: 56px 156px;

  @media (max-width: 1200px) {
    margin-top: 60px;
    padding: calc(40px + 40px) 16px 40px;
  }

  .transfer-part__inner {
    width: fit-content;
    margin: auto;

    @media (max-width: 900px) {
      max-width: none;
      width: 100%;
    }
  }

  .header {
    display: block;
    width: 100%;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 56px;
    @media (max-width: 900px) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  hr {
    height: 0;
    border: 0;
    border-bottom: 1px solid #6D6976;
    margin: 32px 0;
  }

  .container {
    form.transfer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .cards {
    display: flex;
    align-items: stretch;

    .card {
      width: 340px;
      padding: 32px 24px;
      &:first-of-type {
        margin-right: 16px;
      }

      .h3 {
        margin-bottom: 24px;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;

      .card {
        width: calc(100% - 32px);
        padding: 32px 16px;

        &:first-of-type {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
  }

  .inline-fields {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      margin-right: 24px;

      @media (max-width: 600px) {
        margin-right: 12px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    ._big {
      flex-basis: 70%;
    }

    ._small {
      flex-basis: 30%;
    }
  }


  .agree-part {
    display: flex;
    align-items: center;

    a {
      padding-left: 6px;
    }

    a {
      cursor: pointer;
      color: var(--color-primary);
      font-family: Roboto, serif;
      text-decoration: none;
    }
  }

  .finish-step {
    //height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    svg {
      max-width: 100%;
      margin-bottom: 24px;
    }

    .loader {
      margin-bottom: 48px;
    }

    .code {
      margin-top: 56px;
      text-align: left;
    }

    button {
      margin-top: 56px;
      height: 48px !important;
    }
  }
}

.commission {
  width: 100%;

  hr {
    margin: 0;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
  }
}

.submit {
  margin: 48px auto 0 !important;
  height: 58px !important;
  display: block;
}

.icons {
  margin: 0 auto 32px;
  max-width: 450px;
  min-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 700px) {
    min-width: 260px;
  }
}