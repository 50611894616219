.contacts-part {
  background: rgba(0, 0, 0, 0.24);

  form {
    outline: none;
    border: none;
    width: 420px;
    max-height: calc(100% - 100px);
    max-width:  calc(100% - 80px);
    padding: 40px;
    background: var(--color-white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    overflow-x: hidden;

    .loader {
      background: transparent !important;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .h3 {
        margin-bottom: 0;
      }

      button.close-btn, button.close-btn:hover, button.close-btn:focus, button.close-btn:active {
        padding: 0;
        min-height: 0;
        width: 20px;
        height: 20px;
        display: block;
        background: transparent;
        box-shadow: none;
      }
    }

    
    .button-container {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 600px) {
      padding: 32px 16px;

      button {
        width: 100%;
      }
    }
  }

  .success-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .loader {
      margin: 56px 0;
    }

    svg {
      max-width: 100%;
    }
  }

}