@font-face {
  font-family: "HelveticaNeue";
  src: local("HelveticaNeue"), url("./resources/fonts/HelveticaNeue.ttf") format("truetype");
}

  
body {
  min-width: 360px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    color: var(--color-text);
    font-family: 'HelveticaNeue' !important;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 140%;

    b {
      font-weight: bold;
    }
  }

  *::-webkit-scrollbar {
    width: 3px;
    cursor: pointer;
  }

  *::-webkit-scrollbar-track {
    background-color: var(--color-primary-light);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
  }

  #root {
    overflow-x: hidden;
  }
}

div.MuiPaper-root {
  background: var(--color-card);
  color: var(--color-text);
  box-shadow: none;
  border-radius: 0;
  padding: 56px 64px;

  @media (max-width: 1200px) {
    padding: 32px 16px;
  }

  &.MuiMenu-paper {
    padding: 0;
    border: 1px solid #6D6976;
    box-sizing: border-box;
    border-radius: 2px;
    ul {
      padding: 0;
    }

    option {
      cursor: pointer;
      color: var(--color-black);
      padding: 6px 16px;

      &:hover {
        background: var(--color-primary);
        color: var(--color-white);
      }
    }
  }
}

.h2 {
  display: block;
  margin-bottom: 48px;
  font-size: 40px;
  line-height: 50px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &._primary {
    color: var(--color-primary);
  }

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 40px;
  }
}

.h3 {
  display: block;
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  
  &._primary {
    color: var(--color-primary);
  }

  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

span.MuiCheckbox-root {
  padding: 0;
  margin-right: 12px;

  path {
    color: var(--color-text);
  }

  &.Mui-checked {
    path {
      color: var(--color-primary);
    }
  }
}

.margin-bottom {
  margin-bottom: 20px !important;
}


.margin-bottom-auto {
  margin-bottom: auto !important;


  @media (max-width: 900px) {
    margin-bottom: 20px !important;
  }
}

.margin-right {
  margin-right: 20px !important;
}

._clickable {
  cursor: pointer;
}

button.MuiButton-root, button.MuiButton-root:hover {
  height: 58px;
  width: 227px;
  min-width: 200px;
  border: none;
  border-radius: 60px;

  &.primary {
    background: linear-gradient(95.2deg, #27BAFD 5.97%, #1D7CFF 94.05%);
    transition: all 1s;

    &:hover {
      background: linear-gradient(95.2deg, #27BAFD 5.97%, #1D7CFF 94.05%);
    }
  }

  &.secondary {
    background-color: var(--color-card-primary);
    border: 3px solid transparent;
    background-clip: padding-box;

    &:before {
      content: "";
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -3px;
      border-radius: 60px;
      background: linear-gradient(95.2deg, #27BAFD 5.97%, #1D7CFF 94.05%);
    }


    span {
      color: var(--color-primary);
    }
  }

  span {
    color: var(--color-white);
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  &.Mui-disabled {
    //background: rgba(175, 178, 192, 0.4);
    opacity: 0.3;
  }
}

._contents {
  display: contents;
}

.back-link, .back-link:active, .back-link:hover, .back-link:focus {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  width: fit-content;
  position: absolute;
  top: 56px;
  left: 156px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;

  @media (max-width: 1100px) {
    left: 16px;
    top: 48px;
  }

  svg {
    margin-right: 12px;
  }
}

.app-container {
  //height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app._hidden {
  display: none;
}

.df_fdc_jcsb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}