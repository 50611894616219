.advantages-part {
  padding: 140px 32px 70px;
  @media (max-width: 1200px) {
    padding: 56px 32px;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: start;
    grid-row-gap: 56px;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      // margin-bottom: 20px;
      height: 200px;
    }

    span {
      text-align: center;
    }
  }
}