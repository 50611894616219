header.appbar, footer.appbar {
  height: 67px;
  background: var(--color-card-primary);
  box-shadow: none;
  padding: 0 120px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    display: none;
  }

  .logo-container {
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }

  .menu {
    a {
      margin-left: 16px;
      cursor: pointer;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      text-transform: uppercase;

      &._active {
        color: var(--color-primary);
      }
    }
  }
}

.header-short {
  background: var(--color-card-primary);
  box-shadow: 0 1px 40px rgba(0, 0, 0, 0.08);
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;

  button, button:hover, button:focus, button:active {
    box-shadow: none;
    padding: 0;
    background: transparent;
    width: min-content;
    height: min-content;
  }

  @media (max-width: 1200px) {
    display: flex;
  }
}

.header-mobile-menu {
  .MuiBackdrop-root {
    background: rgba(255, 255, 255, 0.4);
  }
  .MuiDrawer-paper {
    padding: 32px;
    border-radius: 0;
    width: 246px;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;

    button.close-btn, button.close-btn:hover, button.close-btn:focus, button.close-btn:active {
      display: block;
      margin-left: auto;
      background: transparent;
      box-shadow: none;
    }

    .menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: auto;
      height: 50%;
      max-height: 450px;

      a {
        cursor: pointer;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;

        &._active {
          color: var(--color-primary);
        }
      }
    }
  }
}


button.lang-button, button.lang-button:hover, button.lang-button:focus, button.lang-button:active {
  background: transparent;
  width: min-content;
  min-width: 0;
  height: min-content;

  padding: 8px 16px;
  border: none;
  border-radius: 40px;

  span {
    width: min-content;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--color-text);
  }

  &:first-of-type {
    margin-right: 16px;
  }

  &._active {
    background: var(--color-white);

    span {
      color: var(--color-primary);
    }
  }
}